/*
 * SPDX-FileCopyrightText: 2023 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { Widgets,
				 Row,
				 Col,
				 CentreContents,
				 Container} from '@tol/tol-ui'
import treeval_logo from '../assets/treeval_logo.png'
import embl_ebi_logo from '../assets/EMBL_EBI_GRC.png'
import mgi_logo from '../assets/MGI_GRC.png'
import ncbi_logo from '../assets/NCBI_GRC.png'
import rgd_logo from '../assets/RGD_GRC.jpg'
import sanger_logo from '../assets/Sanger_GRC.png'
import zfin_logo from '../assets/ZFIN_GRC.png'
import AssemblySelect from '../components/AssemblySelect';
import { Link } from 'react-router-dom'

const about_project = (
	<span>
		<h3>About the Project</h3>

		<p className='mt-2'>The GRC’s mission is to improve model organism reference genome assemblies in its care, correcting errors and adding sequence to ensure it provides the best representation of the respective genome to meet research needs. </p>
		<br />
		<p>For more information about the GRC's activities please visit 
			this <a href="https://www.ncbi.nlm.nih.gov/grc" 
			target="_blank">link</a>.</p>
		<br />
		<p>For the blog sharing news and the latest releases 
			go <a href="https://genomeref.blogspot.com/" 
			target="_blank">here</a>.</p>
	</span>
)

const about_browser = (
	<span>
		<h3>About the Genome Browser</h3>
		<p className='mt-2'>The <a href="https://pipelines.tol.sanger.ac.uk/treeval" 
			target="_blank">TreeVal Genome Browser</a> is maintained by the <a href="https://www.sanger.ac.uk/programme/tree-of-life/" 
			target="_blank">Tree of Life Project</a> at the Wellcome Sanger Institute.</p>
		<br />
		<p className='mt-2'>Evaluation of genome assemblies through 
		its tools and pre-computed analyses facilitate the improvement of overall assemblies to a high standard for release as 
		reference genomes.</p>
		<br />
		<p>It comprises a <a href="https://github.com/sanger-tol/treeval" 
			target="_blank">Nextflow pipeline</a> displayed in the <a href="https://jbrowse.org/jb2/" 
			target="_blank">JBrowse2 viewer</a>.
		</p>
	</span>
)

const assembly = (
	<span>
		<h3>Browse Genome Assemblies:</h3>
	</span>
)

const partners = (
	<span>
		<h3>Partners:</h3>
	</span>
)

const search = (
	<span>

	</span>
)

const about_project_widget = (
	<Row>
		<Col>{about_project}</Col>
	</Row>
)

const about_browser_widget = (
	<Container>
		<Row>
			<Col>{about_browser}</Col>
			<Col className="image-section" sm={3}>
				<img src={treeval_logo} className="tv-logo" />
			</Col>
		</Row>
	</Container>
)

const search_widget = (
	<Container>
		<Row>
			<Col xs={12} sm={8}>{search}</Col>
		</Row>
		<Row>
			<AssemblySelect />
		</Row>
	</Container>
)

const assembly_widget = (
	<Container>
		<Row>
			<Col>{assembly}</Col>
		</Row>
		<Row>
			<Col className="species-card">
				<Link to="/Human">
					<img src="https://images.phylopic.org/images/9c6af553-390c-4bdd-baeb-6992cbc540b1/raster/346x1024.png" className="human-silhouette" />
					<h5>Human</h5>
				</Link>
			</Col>
			<Col className="species-card">
				<Link to="/Mouse">
					<img src="https://images.phylopic.org/images/6b2b98f6-f879-445f-9ac2-2c2563157025/raster/1024x542.png" className="mouse-silhouette" />
					<h5>Mouse</h5>
				</Link>
			</Col>
			<Col className="species-card">
				<Link to="/Rat">
					<img src="https://images.phylopic.org/images/828a8d15-6aa9-41ab-85a3-e9e06c0f1945/raster/1536x462.png" className="rat-silhouette" />
					<h5>Rat</h5>
				</Link>
			</Col>
			<Col className="species-card">
				<Link to="/Zebrafish">
					<img src="https://images.phylopic.org/images/5155f7c4-4609-44ed-a5e9-b3ba64b574e3/raster/374x120.png" className="zebrafish-silhouette" />
					<div>
						<h5>Zebrafish</h5>
					</div>
				</Link>
			</Col>
			<Col className="species-card">
				<Link to="/Tasmanian-Devil">
					<img src="https://images.phylopic.org/images/58cc56c2-5a36-4031-be9f-28c86f77963c/raster/1024x552.png" className="td-silhouette" />
					<h5>Tasmanian Devil</h5>
				</Link>
			</Col>
		</Row>
	</Container>
)

const partners_widget = (
	
		<Container>
			<Row>
				<Col>{partners}</Col>
			</Row>
			<div className='bg-white'>
			<Row>
				<Col className="species-card">
					<Link to="https://www.sanger.ac.uk/research/areas/bioinformatics/grc">
						<img src={sanger_logo} className="sanger-logo" alt="Wellcome Sanger Institute" />
					</Link>
				</Col>
				<Col className="species-card">
					<Link to="http://genome.wustl.edu">
						<img src={mgi_logo} className="mgi-logo" alt="The McDonnell Genome Institute at Washington University" />
					</Link>
				</Col>
				<Col className="species-card">
					<Link to="https://www.ebi.ac.uk">
						<img src={embl_ebi_logo} className="emblebi-logo" alt="The European Bioinformatics Institute" />
					</Link>
				</Col>
				<Col className="species-card">
					<Link to="https://www.ncbi.nlm.nih.gov">
						<img src={ncbi_logo} className="ncbi-logo" alt="The National Center for Biotechnology Information" />
					</Link>
				</Col>
				<Col className="species-card">
					<Link to="http://zfin.org">
						<img src={zfin_logo} className="zfin-logo" alt="The Zebrafish Model Organism Database" />
					</Link>
				</Col>
				<Col className="species-card">
					<Link to="https://rgd.mcw.edu/">
						<img src={rgd_logo} className="rgd-logo" alt="Rat Genome Database" />
					</Link>
				</Col>
			</Row>
			</div>
		</Container>

)

function Home() {
  return (
    <div className="tv-grc">
		<CentreContents>
			<Widgets components={[assembly_widget]}/>
			<Widgets components={[
									about_project_widget,
									about_browser_widget
								]}/>
			<Widgets components={[
						partners_widget
					]}/>
		</CentreContents>
	</div>
  );
}
export default Home;