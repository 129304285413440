/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Col, Row } from '@tol/tol-ui';

interface Props { 
    assembly_name: string,
    assembly_name_alt: string,
    assembly_name_patch: string,
    assembly_strain: string,
    assembly_strain_link: string,
    assembly_chr: string, 
    assembly_chr_alt: string, 
    assembly_chr_patch: string, 
    assembly_type: string,
    release_date: string,
    release_type: string,
    assembly_units: string,
    bases_total: string,
    bases_nonn_total: string,
    assembly_n50: string,
    regions_total: string,
    regions_alternate: string,
    regions_fix: string,
    regions_novel: string,
    regions_par: string,
    assembly_summary: string,
    jbrowse_tracks: string,
    jbrowse_tracks_alt: string,
    jbrowse_tracks_patch: string
}

function Assembly(props: Props) {
    const { assembly_name, 
            assembly_name_alt,
            assembly_name_patch,
            assembly_strain,
            assembly_strain_link,
            assembly_chr, 
            assembly_chr_alt,
            assembly_chr_patch,
            assembly_type,
            release_date,
            release_type,
            assembly_units,
            bases_total,
            bases_nonn_total,
            assembly_n50,
            regions_total,
            regions_alternate,
            regions_fix,
            regions_novel,
            regions_par,
            assembly_summary,
            jbrowse_tracks,
            jbrowse_tracks_alt,
            jbrowse_tracks_patch
    } = props;
  
    const openBrowser = (name: string, chr: string, tracks: string) => {
        let assembly_path = 'http://jbrowse-grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly='+ name + '&session=spec-{"views":[{"assembly":"' + name + '","loc":"' + chr + '","type": "LinearGenomeView","tracklist": "true","tracks":[' + tracks + ']}]}'
        let path:string = assembly_path!  
        
        window.open(path, '_blank');
    };

    const release_date_row = (
        <Row>
            Release Date: {release_date}
        </Row>
    )
    const release_type_row = (
        <Row>
            Release Type: {release_type}
        </Row>
    )
    const assembly_units_row = (
        <Row>
            Assembly units: {assembly_units}
        </Row>
    )
    const bases_total_row = (
        <Row>
            Total bases: {bases_total}
        </Row>
    )
    const bases_nonn_total_row = (
        <Row>
            Total non-N bases: {!bases_nonn_total ? " - " : bases_nonn_total}
        </Row>
    )
    const assembly_n50_row = (
        <Row>
            Primary assembly N50: {!assembly_n50 ? " - " : assembly_n50}
        </Row>
    )

    const regions_total_row = (
        <Row>
            Total Regions: {!regions_total ? " - " : regions_total}
        </Row>
    )
    const regions_alternate_row = (
        <Row>
            Regions with alternate loci: {!regions_alternate ? " - " : regions_alternate}
        </Row>
    )
    const regions_fix_row = (
        <Row>
            Regions with FIX loci: {!regions_fix ? " - " : regions_fix}
        </Row>
    )
    const aregions_novel_row = (
        <Row>
            Regions with NOVEL patches: {!regions_novel ? " - " : regions_novel}
        </Row>
    )
    const regions_par_row = (
        <Row>
            Regions as PAR: {!regions_par ? " - " : regions_par}
        </Row>
    )

    const primary_browser = (
        <Row>
            <Button className="browser-button" size="sm" href="#" onClick={() => openBrowser(assembly_name, assembly_chr, jbrowse_tracks)} target="_blank">Open Browser</Button>
        </Row>
    )

    const alt_browser = (
        <Row>
            <Button className="browser-button" size="sm" href="#" onClick={() => openBrowser(assembly_name_alt, assembly_chr_alt, jbrowse_tracks_alt)} target="_blank">Open Alt Browser</Button>
        </Row>
    )

    const patch_browser = (
        <Row>
            <Button className="browser-button" size="sm" href="#" onClick={() => openBrowser(assembly_name_patch, assembly_chr_patch, jbrowse_tracks_patch)} target="_blank">Open Patch Browser</Button>
        </Row>
    )

    return (
        <div>
            <Container className="treeval-home-btns">
                <Row>
                    <Col>
                        <h4>{assembly_summary}</h4>
                        <h6><a href={assembly_strain_link} target="_blank">{assembly_strain}</a></h6>
                        <h6>{assembly_type}</h6>                        
                    </Col>
                    <Col>
                        {!release_date ? '' : release_date_row}
                        {!release_type ? '' : release_type_row}
                        {!assembly_units ? '' : assembly_units_row}
                        {!bases_total ? '' : bases_total_row}
                        {!bases_nonn_total ? '' : bases_nonn_total_row}
                        {!assembly_n50 ? '' : assembly_n50_row}
                    </Col>
                    <Col>
                        {!regions_total ? '' : regions_total_row}
                        {!regions_alternate ? '' : regions_alternate_row}
                        {!regions_fix ? '' : regions_fix_row}
                        {!regions_novel ? '' : aregions_novel_row}
                        {!regions_par ? '' : regions_par_row}
                    </Col>
                    <Col>
                        {!assembly_name ? '' : primary_browser}
                        {!assembly_name_alt ? '' : alt_browser}
                        {!assembly_name_patch ? '' : patch_browser}
                    </Col>
                </Row>
            </Container>        
        </div>
    );
}

export default Assembly;