/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import { Button } from '@tol/tol-ui';





function AssemblySelect() {

    const [pickerValue, setPickerValue] = useState<string | null>(null);

    const openBrowser = () => {
        let path:string = pickerValue!  
        window.open(path, '_blank');


    };

    return (
        <div>
            <SelectPicker 
                size="lg" 
                label="Assembly" 
                data={[{ label: "Human (CHM13T2Tv2.0)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=CHM13T2Tv2.0&session=spec-{"views":[{"assembly":"CHM13T2Tv2.0","loc":"chr1","type": "LinearGenomeView","tracks":["CHM13T2Tv2.0-ReferenceSequenceTrack"]}]}' }, 
                        { label: "Human (GRCh38p14)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=GRCh38p14_patch&session=spec-{"views":[{"assembly":"GRCh38p14_patch","loc":"chr9_MU273365v1_fix","type": "LinearGenomeView","tracks":["GRCh38p14_patch-ReferenceSequenceTrack"]}]}' },
                        { label: "Mouse (GRCm39)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=GRCm39&session=spec-{"views":[{"assembly":"GRCm39","loc":"chr1","type": "LinearGenomeView","tracks":["GRCm39-ReferenceSequenceTrack"]}]}' },
                        { label: "Rat (mRatBN7.2)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=mRatBN7.2&session=spec-{"views":[{"assembly":"mRatBN7.2","loc":"chr1","type": "LinearGenomeView","tracks":["mRatBN7.2-ReferenceSequenceTrack"]}]}' },
                        { label: "Tasmanian devil (mSarHar1.11)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=mSarHar1.11&session=spec-{"views":[{"assembly":"mSarHar1.11","loc":"Super_scaffold_1","type": "LinearGenomeView","tracks":["mSarHar1.11-ReferenceSequenceTrack"]}]}' },
                        { label: "Zebrafish (GRCz11)", value: 'http://jbrowse.grc.tol.sanger.ac.uk/jbrowse2/?config=config.json&assembly=GRCz11_alt&session=spec-{"views":[{"assembly":"GRCz11_alt","loc":"chr1_KZ114997v1_alt","type": "LinearGenomeView","tracks":["GRCz11_alt-ReferenceSequenceTrack"]}]}' } ]} 
                style={{width: 500}}
                value={pickerValue}
                onChange={setPickerValue}
            />
            {/* {jbrowse_button} */}
            <Button disabled={!pickerValue} href="#" onClick={() => openBrowser()} target="_blank">Open Browser</Button>
            {/* <Link to={assemblyBrowserLink}>
                <Button>Open Browser</Button>
            </Link> */}
        </div>
    );
}

export default AssemblySelect;